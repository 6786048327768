(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "reactDOM", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["TagCloudViewerWidget"] = factory(require("react"), require("react-dom"), require("lodash"));
	else
		root["TagCloudViewerWidget"] = factory(root["React"], root["ReactDOM"], root["_"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__60751__, __WEBPACK_EXTERNAL_MODULE__83202__, __WEBPACK_EXTERNAL_MODULE__16252__) => {
return 